import Axios from "axios";
// const token = localStorage.getItem("Authorization");
const proxy = window.config.api.url;

// 获取门票详情
const getTicketDetail = id => Axios.get(`${proxy}/tickets/${id}`);

// 获取活动信息
const getActivitys = data => Axios.get(`${proxy}/tickets`, data);

// 获取折扣信息
const getDiscounts = id => Axios.get(`${proxy}/tickets/${id}/discounts`);

// 创建订单
const createOrder = data => Axios.post(`${proxy}/orders`, data);

// 支付链接
const buyOrderUrl = id => `${proxy}/api/alipay?orderId=${id}`;

// 查询订单
const getOrderDetail = id => Axios.get(`${proxy}/orders/${id}`);

// 获取订单
const getOrderInfo = () => Axios.get(`${proxy}/orders/user`);

// 获取二维码
const getQrCodeUrl = id => `${proxy}/orders/qr_code/${id}`;

// 获取token
const getSessions = data => Axios.post(`${proxy}/sessions`, data);

// 微信支付链接获取
const getWxUlr = (id, result) =>
  `${proxy}/api/wx/pay?orderId=${id}&fingerprint=${result}`;

// 创建发票
const addInvoice = data => Axios.post(`${proxy}/invoice`, data);

// 微信公众平台授权登陆
const wxCpLogin = code => Axios.get(`${proxy}/api/wx/mp/login?code=${code}`);

// 获取微信分享配置
const getWxSignature = url => Axios.get(`${proxy}/api/wx/mp/signature?url=${url}`);

// 获取场馆布局详情
const getTsRoomLayout = id => Axios.get(`${proxy}/ts_room_layout/${id}`);

// 获取场馆布局
const getTsRoomLayoutList = roomId => Axios.get(`${proxy}/ts_room_layouts/list?roomId=${roomId}`);

// 获取场馆席位作为门票种类
const getTicketCategoryList = roomId => Axios.get(`${proxy}/ts_room_layouts/areas?roomId=${roomId}`);

// 获取场馆布局列表
const getTsRoomLayoutPage = data =>
  Axios.get(`${proxy}/ts_room_layouts/page`, data);

// 获取活动座位信息
const getTsTicketSeats = data =>
  Axios.get(`${proxy}/ts_ticket_seats/page`, data);

// 获取区域信息
const getTsRoomArea = data => Axios.get(`${proxy}/ts_room_areas/page`, data);

export {
  getTicketDetail,
  getActivitys,
  createOrder,
  buyOrderUrl,
  getOrderDetail,
  getOrderInfo,
  getQrCodeUrl,
  getSessions,
  getWxUlr,
  addInvoice,
  wxCpLogin,
  getWxSignature,
  getDiscounts,
  getTsRoomLayout,
  getTsRoomLayoutList,
  getTsRoomLayoutPage,
  getTsTicketSeats,
  getTsRoomArea,
  getTicketCategoryList
};
