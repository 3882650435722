<template>
  <div id="app">
    <router-view v-if="showPage" />
    <div class="public-button-wrap">
      <el-button
        v-for="(item, i) in menus"
        :key="i"
        type="text"
        :class="{ active: active === i }"
        @click="targetHandle(item, i)"
      >
        <div :class="[item.className, 'icon_']"></div>
        <div>{{ item.label }}</div>
      </el-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { wxCpLogin } from "@/api/index.js";

export default {
  computed: {
    login() {
      return this.$store.state.normal.login;
    }
  },
  data() {
    return {
      active: 0,
      showPage: false,
      menus: [
        {
          label: "首页",
          className: "el-icon-s-home",
          routeName: "Home"
        },
        {
          label: "我的",
          className: "el-icon-user-solid",
          routeName: "Mine"
        }
      ]
    };
  },
  created() {
    localStorage.setItem(
      "Authorization",
      "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxIiwiZXhwIjoxNzIyNjc1MTQ2LCJ1c2VySWQiOjEsImlhdCI6MTcyMjU4ODc0NiwiYWNjb3VudCI6ImFkbWluIiwidXNlcktleSI6Inh4eHgifQ.N061avJ9s5gmyuTcD23OHNhYenYa5SFqVcosynXyS3mW-361IAIhS8W_gimTsbPlaDzR-0yENDi0YhwLT_KBbg"
    );
    localStorage.setItem("customerName", "admin");
    // 微信公众平台登陆token
    if (this.$route.query.token) {
      localStorage.setItem("Authorization", this.$route.query.token);
    }
    // 微信公众平台登陆code
    if (this.$route.query.code) {
      this.onWxCpLogin(this.$route.query.code);
    } else {
      this.showPage = true;
    }
  },
  watch: {
    login(val) {
      console.log(val);
    },
    "$route.name"(val) {
      if (val.startsWith("Mine")) {
        this.active = 1;
      } else {
        this.active = 0;
      }
    }
  },
  methods: {
    targetHandle(target, i) {
      this.active = i;
      this.$router.push({
        name: target.routeName
      });
    },
    onWxCpLogin(code) {
      wxCpLogin(code).then(res => {
        if (res) {
          localStorage.setItem("Authorization", res.token);
          localStorage.setItem("customerName", res.customer.name);
        }
        this.showPage = true;
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #2c3e50
  height 100%
  overflow auto
  position relative
  .public-button-wrap {
    height: 60px;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    border-top: 1px solid #cccccc;
    display: flex;
    button {
      width: 50%;
      .icon_ {
        font-size: 25px;
        margin-bottom: 3px;
      }
      color: #666666;
      &.active {
        color: #57b837;
      }
    }
  }
</style>
