<template>
  <div class="home-wrapper">
    <el-carousel :interval="5000" arrow="always">
      <el-carousel-item
        v-for="(item, i) in recommend.filter(v => v.coverImage)"
        :key="i"
      >
        <img :src="item.coverImage" @click="activityTargetHandle(item)" />
      </el-carousel-item>
    </el-carousel>
    <div class="options-card">
      <div class="header">
        <span>推荐活动</span>
      </div>
      <ul class="activity-list" v-if="activitys.length">
        <li
          v-for="(item, i) in recommend"
          :key="item.id"
          @click="activityTargetHandle(item)"
        >
          <div class="info">
            <img :src="imgArr[i % imgArr.length]" />
            <div class="name">{{ item.name }}</div>
            <!-- <div class="introduce" v-if="item.content">{{ item.content }}</div> -->
          </div>
          <div class="detail">
            <div class="price">￥{{ item.price }}</div>
            <div class="group">
              <span class="address">{{ item.address }}</span>
              <span class="date">{{ item.startTime }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div v-else class="empty">暂无活动</div>
    </div>
    <div class="options-card">
      <div class="header">
        <span>历史活动</span>
        <!-- <span class="sub">按照活动时间向下滚动展示</span> -->
      </div>
      <div class="history-list" v-if="historys.length">
        <div
          class="history-item"
          v-for="(item, i) in historys"
          :key="i"
          @click="activityTargetHandle(item)"
        >
          <!-- <img :src="icon" /> -->
          <div>
            <div class="name">
              <span>{{ item.name }}</span>
              <span class="price">￥{{ item.price }}</span>
            </div>
            <div class="address">{{ item.address }}</div>
          </div>
          <div class="date">{{ item.startTime }}</div>
        </div>
      </div>
      <div v-else class="empty">暂无活动</div>
    </div>
  </div>
</template>

<script>
import { getActivitys } from "@/api/index.js";
const img1 = require("@/assets/img/activity-bg1.png");
const img2 = require("@/assets/img/activity-bg2.png");
const img3 = require("@/assets/img/activity-bg3.png");
const img4 = require("@/assets/img/activity-bg4.png");

export default {
  name: "Home",
  data() {
    return {
      imgArr: [img1, img2, img3, img4],
      activitys: []
    };
  },
  computed: {
    historys() {
      return this.activitys.filter(v => v.status === "COMPLETED");
    },
    recommend() {
      return this.activitys.filter(v => v.status === "IN_PROGRESS");
    }
  },
  created() {
    // this.contentImgs = this.activity.content.split(",");
    this.getActivitys();
  },
  methods: {
    getActivitys() {
      getActivitys().then(({ code, data }) => {
        if (code === 0) {
          data.forEach(v => {
            v.startTime = v.startTime.substr(0, 16);
          });
          this.activitys = data;
        }
      });
    },
    activityTargetHandle(item) {
      this.$router.push({
        name: "Activity",
        params: {
          activityId: item.id
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.bg-banner {
  width: 100%;
  z-index: -1;
}

.home-wrapper {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 60px;
  .el-carousel {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  >>> .el-carousel__container {
    height: 47.2vw;
  }
  .el-carousel {
    margin-bottom: 5px;
  }
  .options-card {
    .header {
      font-size: 1.4em;
      color: #333333;
      letter-spacing: 1px;
      padding-left: 10px;
      padding-top: 5px;
      font-weight: bold;
      .sub {
        font-size: 0.6em;
        color: #a7a7a7;
        padding-left: 5px;
        font-weight: normal;
      }
    }
    .empty {
      text-align: center;
      color: #cccccc;
      padding: 10px;
    }
  }
  .activity-list {
    padding: 5px 0;
    margin: 5px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: calc(50% - 5px);
      margin-bottom: 10px;
      border-radius: 8px;
      .info {
        position: relative;
        color: #ffffff;
        img {
          width: 100%;
          height: auto;
          display: block;
        }
        .name {
          position: absolute;
          top: 30%;
          left: 10px;
          font-size: 1.4em;
        }
        .introduce {
          position: absolute;
          top: 60%;
          left: 10px;
          font-size: 1.2em;
        }
      }
      .detail {
        padding: 5px 8px 15px;
        background-color #ffffff;
        border-radius: 8px;
        .price {
          font-size: 1.4em;
          font-weight: bold;
          color: #65b642;
        }
        .group {
          padding-top: 10px;
          color: #666666;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // &::after {
          //   content: '';
          //   display: block;
          //   clear: both;
          // }
          .address {
            // float: left;
            font-size: 1em;
            &::before {
              content: '';
              float: left;
              width: 1.4em;
              height: 1.4em;
              background-image: radial-gradient(#65b642 0, #65b642 40%, #cce7c1 44%, #cce7c1 70%, #ffffff 75% );
              margin-right: 5px;
            }
          }
          .date {
            // float: right;
            padding-top: 3px;
            font-size: 0.8em;
          }
        }
      }
    }
  }
  .history-item {
    display: flex;
    padding-left: 60px;
    padding-right: 10px;
    background-image: url("../assets/img/icon-favorite.png");
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    background-position: 10px 15px;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    margin: 10px;
    .name {
      color: #333333;
      font-size: 1.2em;
      font-weight: bold;
      .price {
        color: #65b642;
      }
    }
    .address {
      margin-top: 5px;
      color: #999898;
      font-size: 1em;
    }
    .date {
      color: #7d7d7d;
      font-size: .8em;
    }
  }
  // >>> .el-carousel__arrow {}
}
</style>
