import Axios from "axios";
import { Message } from "element-ui";
// import store from "../store/index.js";

let apiIntercept = {};

apiIntercept.install = (vue, options) => {
  // console.log("options", options);
  // vue.prototype.$axios = Axios;
  // http request 拦截器
  Axios.interceptors.request.use(
    config => {
      config.headers.common["Authorization"] = localStorage.getItem(
        "Authorization"
      );

      // config.headers.common["content-type"] = "application/json";

      // 时间戳
      config.url += `${/\?/.test(config.url) ? "&" : "?"}_t=${Date.now()}`;
      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );
  // http response 拦截器
  Axios.interceptors.response.use(
    response => {
      if (response.data.code === 1502) {
        // store.commit("normal/SET_LOGIN", false);
        let state = options.router.app.$route.query.state;
        if (state !== "3") {
          if (/^\d+$/.test(state)) {
            state = +state + 1;
          } else {
            state = 1;
          }
          window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.config.appid}&redirect_uri=${window.config.redirect}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
        } else {
          Message.warning({
            message: "登录失败",
            duration: 5000
          });
        }
      }
      return response.data; // 返回接口返回的错误信息
    },
    err => {
      return Promise.reject(err);
    }
  );
};

export default apiIntercept;
