import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import apiIntercept from "@/api/apiIntercept";
import elementUI from "element-ui";
import store from "./store/index.js";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/style.styl";
import "@/assets/reset.styl";
// import Vant from "vant";
// import "vant/lib/index.css";

// 注册统一拦截
Vue.use(apiIntercept, { router });

Vue.use(elementUI);

// Vue.use(Vant);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
