const state = {
  login: "默认子vuex文本",
  activity: {}
};

const getters = {};

const actions = {};

const mutations = {
  SET_LOGIN: (state, val) => {
    state.login = val;
  },
  SET_ACTIVITY: (state, val) => {
    state.activity = val;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
