import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/activity/:activityId",
    name: "Activity",
    component: () => import("../views/Activity.vue")
  },
  {
    path: "/buy-ticket",
    name: "BuyTicket",
    component: () =>
      import(/* webpackChunkName: "BuyTicket" */ "../views/BuyTicket.vue"),
    children: [
      {
        path: "/buy-ticket/alipay",
        name: "Alipay",
        component: () =>
          import(/* webpackChunkName: "Alipay" */ "../views/Alipay.vue")
      },
      {
        path: "/buy-ticket/wxpay",
        name: "Wxpay",
        component: () =>
          import(/* webpackChunkName: "Wxpay" */ "../views/Wxpay.vue")
      },
      {
        path: "/buy-ticket/seat-selection",
        name: "SeatSelection",
        component: () =>
          import(
            /* webpackChunkName: "SeatSelection" */ "../views/SeatSelection.vue"
          )
      }

      // {
      //   path: "/buy-ticket/wxpay",
      //   name: "Wxpay",
      //   component: () =>
      //     import(/* webpackChunkName: "Wxpay" */ "../views/Wxpay.vue")
      // }
    ]
  },
  {
    path: "/mine",
    name: "Mine",
    component: () => import(/* webpackChunkName: "Mine" */ "../views/Mine.vue"),
    children: [
      {
        path: "/mine/detail/:id",
        name: "MineDetail",
        component: () =>
          import(/* webpackChunkName: "MineDetail" */ "../views/MineDetail.vue")
      }
    ]
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: () =>
      import(/* webpackChunkName: "Invoice" */ "../views/Invoice.vue")
  },
  {
    path: "/edit-info",
    name: "EditInfo",
    component: () =>
      import(/* webpackChunkName: "EditInfo" */ "../views/EditInfo.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
